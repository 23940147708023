.cont-p {
    display: flex;
    flex-direction: column;

    -webkit-border-radius: 6px 6px 6px 6px;
    border-radius: 6px 6px 6px 6px;

    width: 96%;
    position: relative;

    overflow-y: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.cont-p::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
}

.vspace {
    display: flex;
    width: 100%;
    height: 1vh;
}


.fs-top {
    font-size: clamp(1.563rem, 0.769rem + 2.308vw, 2.5rem);
}
.fs-txt {
    font-size: clamp(0.938rem, 0.462rem + 1.385vw, 1.5rem);
}
.fs-val {
    font-size: clamp(0.938rem, 0.462rem + 1.385vw, 1.5rem);
}
.fs-menu-but {
    font-size: clamp(0.7rem, 0.462rem + 1.385vw, 1.1rem);
}
/* font-size: clamp(1.125rem, 0.49rem + 1.846vw, 1.875rem); */

.row-g {
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-columns: 1%;

    justify-content: start;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 4.2vh;
}

.itemg {
    display: flex;
    align-items: center;
    height: 100%;
    grid-row: 1 / span 1;
}

.itemg-s {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
    grid-row: 1 / span 1;
}

.itemg-e {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
    grid-row: 1 / span 1;
}


.newfont {
    font-family: -apple-system, BlinkMacSystemFont, 'Calibri', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}