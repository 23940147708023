.grid-container {
  width: 100%;
  max-width: 900px;

  display: grid;
  background-color: #FFF;
  grid-auto-columns: 0.5%;
  grid-auto-rows: 0.5%;

  user-select: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
}

.centerXY {
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  user-select: none;
}

.divincontxxxx {
  position: 'absolute';
  top: 20px;
  bottom: 0px;
  left: 20px;
  right: 0px;
  background-color: 'red'
}