/* Date picker ***********************/


.datePickerxxx {
    width: 60%;
    /* display:flex; */
    flex: 1;
    border: 1px solid lightgray;
    border-radius: 5px;
    align-items: center;
    /* user-select: none;  */
    font-size: 1.2em;
  }

  /* input{
    pointer-events:none;
    border-style: none;
    font-size: 0.8em;
    color:rgb(50,50,50);
    background: transparent;
    margin-left: 5px;
    user-select: none; 
  }:focus{
    outline-width: 0;
  } */

  .custom-input {
    width: 100%;
    padding: 3px;
    border: none;
    /* border: 1px solid lightgray;
    border-radius: 4px; */
    font: inherit;
    color: rgb(0, 0, 0);
    background: transparent;

}

  .sipka{
    flex:1;
    display:flex;
    justify-content: center;
    align-items: center;
    color:gray;
    user-select: none; 
    z-index: 1;
    /* background: rgb(252, 140, 140); */
  }
  .sipka:hover{
    cursor:pointer;
  }
  .sipka:active{
    background:lightgreen;
  }



  /* Aktualni hodnoty *************************************/

  /* Link *************************************/

    .link_cont {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        height: 100%;
        font-size: 0.95em; 
        white-space: nowrap;
    }
    .link_under {
        border-bottom: 2px solid #007BFF;
        border-top: 2px solid transparent;
        z-index: 0;
    }

    a {
        color: rgb(55, 55, 55);
        text-decoration: none;
    }
    .linkact {
        color: #007BFF;
        text-decoration: none;
    }
    a:hover {
        color: #007BFF;
        text-decoration: none;
    }



    .media_f_direction{
        display: flex;
        flex-direction: row;
    }

    .acthodnotyPulka{
        flex: 1;
        display: flex;
        flex-direction: row;
    }


    .popisL{
        font-size: 4vw;
        flex: 2;
        display: flex;
        align-items: center;
    }
    .popisNad{
        flex: 1; 
        display: flex;
        font-size: 1.4vw;
        align-items: center;
        justify-content: center;
    }


    @media screen and (min-width: 1400px){
    
        .popisL{
            font-size: 3.5vw;
        }

        .popisNad{
            font-size: 1.2vw;
        }
    
    }


  /* phone */

  @media screen and (max-width: 600px){

    .popisL{
        font-size: 8vw;
        flex: 2;
        display: flex;
        align-items: center;
    }

    .popisNad{
        flex: 1; 
        display: flex;
        font-size: 3vw;
        align-items: center;
        justify-content: center;
    }

  }

