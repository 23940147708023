.gblok {
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-columns: 1wv;
}

.radek {
    display: flex;
    flex-direction: row;
    min-height: 4vh;
    height: auto;
    padding-left: 6px;
    border-top: 2px solid #ffffff;
    border-bottom: 1px solid #8c8c8c;
    background: #E9ECEF;
    align-items: center;
    user-select: none;
}

.radek-noline {
    display: flex;
    flex-direction: row;
    min-height: 4vh;
    height: auto;
    padding-left: 6px;
    background: #ccc;
    align-items: center;
    user-select: none;
}

.radekclear {
    display: flex;
    flex-direction: row;
    height: 4vh;
    padding-left: 10px;
    align-items: center;
    user-select: none;
    background: rgb(220, 220, 220);
}

.radekclearRegion {
  display: flex;
  flex-direction: row;
  height: 6vh;
  padding-left: 10px;
  align-items: center;
  user-select: none;
  background: rgb(220, 220, 220);
}

.radekmoderator {
    display: flex;
    flex-direction: row;
    height: 4vh;
    background: rgb(220, 220, 220);
    padding-left: 10px;
    border-top: 2px solid rgb(255, 255, 255);
    border-bottom: 1px solid rgb(140, 140, 140);
    align-items: center;
    user-select: none;
}

.cara1 {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0;
  margin-right: 0;
  border: none;
  height: 2px;
  background-color: #F7F7F7;
}
.cara5 {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0;
  margin-right: 0;
  border: none;
  height: 20px;
  background-color: #F7F7F7;
}

.fno {flex: none;}
.f05 {flex: 0.5;}
.f1 {flex: 1;}
.f2 {flex: 2;}
.f3 {flex: 3;}
.f4 {flex: 4;}
.f5 {flex: 5;}
.f6 {flex: 6;}
.f7 {flex: 7;}
.f8 {flex: 8;}
.f9 {flex: 8;}
.f10 {flex: 10;}
.f11 {flex: 11;}
.f12 {flex: 12;}
.f14 {flex: 14;}
.f15 {flex: 15;}
.f16 {flex: 16;}
.f18 {flex: 18;}
.f19 {flex: 19;}
.f20 {flex: 20;}
.f26 {flex: 26;}

.btncon {
    display: block;
    position: relative;
    width: 3vh;
    height: 3vh;
    /* background-color: red; */
}

.btncon:hover {
    cursor: pointer;
}


.icocon {
    fill: rgb(46, 46, 46);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.icocon:active { fill: rgb(74, 125, 255);
}

.df { display: flex;
}

.dfc {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fdir-c { flex-direction: column; }
.fdir-r { flex-direction: row; }

.jcs { justify-content: start; }
.jcc { justify-content: center; }
.jce { justify-content: end; }
.jc-s { justify-content: start; }
.jc-c { justify-content: center; }
.jc-e { justify-content: end; }

.ais { align-items: start; }
.aic { align-items: center; }
.aie { align-items: end; }
.ai-s { align-items: start; }
.ai-c { align-items: center; }
.ai-e { align-items: end; }

.fo-b { font-weight: bold; }
.fo-n { font-weight: normal; }
.tx-ac { text-align: center; }
.fs-c1 { font-size: clamp(0.5em, 1vw, 1em); }
.fs-c2 { font-size: clamp(0.6em, 1.3vw, 1.0em); }
.fs-c3 { font-size: clamp(0.8em, 1.3vw, 1.2em); }
.fs-c4 { font-size: clamp(0.8em, 1.3vw, 1.0em); }
.fs-c6 { font-size: clamp(1.2em, 1.4vw, 1.4em); }
.fs-c71 { font-size: clamp(0.75em, 1.6vw, 1.0em); }

.fs-cuwm { font-size: clamp(0.6em, 1.3vw, 1em); }
.fs-cSelect { font-size: clamp(0.75em, 1.3vw, 1.0em); }

.bg-0 {background: #000;}
.bg-1 {background: #111;}
.bg-2 {background: #222;}
.bg-3 {background: #333;}
.bg-4 {background: #444;}
.bg-5 {background: #555;}
.bg-6 {background: #666;}
.bg-7 {background: #777;}
.bg-8 {background: #888;}
.bg-9 {background: #999;}
.bg-10 {background: #AAA;}
.bg-11 {background: #BBB;}
.bg-12 {background: #CCC;}
.bg-13 {background: #DDD;}
.bg-14 {background: #E9ECEF;}
.bg-15 {background: #FFF;}
.bg-16 {background: #F7F7F7;}

.bg-d {background: #ddd;}
.bg-dx {background: red;}

.cl-0 {color: #000;}
.cl-1 {color: #111;}
.cl-2 {color: #222;}
.cl-3 {color: #333;}
.cl-4 {color: #444;}
.cl-5 {color: #555;}
.cl-6 {color: #666;}
.cl-7 {color: #777;}
.cl-8 {color: #888;}
.cl-9 {color: #999;}
.cl-10 {color: #AAA;}
.cl-11 {color: #BBB;}
.cl-12 {color: #CCC;}
.cl-13 {color: #DDD;}
.cl-14 {color: #E9ECEF;}
.cl-15 {color: #FFF;}
.cl-m {color: #007BD2;}


.fx4 {width: 8vw;}

.fs2 {font-size: 2vw;}
.fs25 {font-size: 2.5vw;}
.fs3 {font-size: 3vw;}
.fs4 {font-size: 4vw;}
.fs5 {font-size: 6vw;}


.w3 { width: 3vw;}
.w4 { width: 4vw;}
.w5 { width: 5vw;}
.w6 { width: 6vw;}
.w8 { width: 8vw;}
.w10 {width: 10vw;}
.w12 {width: 12vw;}
.w15 {width: 15vw;}
.w18 {width: 18vw;}
.w20 {width: 20vw;}
.w25 {width: 25vw;}

.hv {background: red;}

.hov{
    cursor:default;
  }
  .hov:hover{
    cursor:pointer;
  }
  .hov-c:hover{
    cursor:pointer;
    color:#ddd;
  }

  .act{
    background:redx;
  }
  .act:active{
    background:#CCC;
  }
  .act-c:active{
    color:#CCC;
  }


  .h100{  height: 100%; }
  .w100{ width: 100%; }

  .h50{  height: 50%; }
  .w50{ width: 50%; }
  .h80{  height: 80%; }
  .w80{ width: 80%; }

  .brd-l{
    border-left: 1px solid #000;
  }

  .brd-r{
    border-right: 1px solid #000;
  }

  .brd-r8{
    border-right: 1px solid #888;
  }

  .pos-r{position: relative; }
  .pos-a{position: absolute; }



